<template>
  <!--Metadata component-->
  <SetMetaData
    v-if="$store.state.metaData"
  />
  <!--Header bar-->
  <HeaderBar @changePage="scrollToPage" />
  
  <router-view/>
</template>

<script>
import HeaderBar from '@/layouts/HeaderBar.vue';
import SetMetaData from '@/components/SetMetaData.vue';

export default{
  components:{
    HeaderBar,
    SetMetaData
  },
  watch: {
    '$route'(to) {
      // On page changing, set page name to storage
      this.$store.commit('setActivePage', to.name);
      // Scroll to top
      window.scrollTo(0,0)
    }
  },
  async created(){
      await this.setLocalDbData();
  },
  mounted() {
    // Добавляем слушатель события скролла при монтировании компонента
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    // Удаляем слушатель события скролла перед уничтожением компонента
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods:{
    handleScroll(){
      requestAnimationFrame(() => {
        const scroll_position = window.scrollY;
        this.$store.commit('setScrollPosition', scroll_position);
      });
    },
    async setLocalDbData(){
      // Set metadata to store
      try {
          const response = await fetch('/db/metadata.json');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.$store.commit('setMetaData', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }
      // Set pages to store
      try {
          const response = await fetch('/db/pages.json');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.$store.commit('setPages', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }
      // Set services to store
      try {
          const response = await fetch('/db/services.json');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }
          const data = await response.json();
          this.$store.commit('setServices', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }

    // Set products to store
      try {
          const response = await fetch('/db/products.json');
          const data = await response.json();
          this.$store.commit('setProducts', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }

    // Set team to store
      try {
          const response = await fetch('/db/team.json');
          const data = await response.json();
          this.$store.commit('setTeam', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }

    // Set statistics to store
      try {
          const response = await fetch('/db/statistics.json');
          const data = await response.json();
          this.$store.commit('setStatistics', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }

    // Set contacts to store
      try {
          const response = await fetch('/db/contacts.json');
          const data = await response.json();
          this.$store.commit('setContacts', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }

    // Set educations to store
      try {
          const response = await fetch('/db/education.json');
          const data = await response.json();
          this.$store.commit('setEducation', data);
      } catch (error) {
          console.error('There was a problem with the fetch operation:', error);
      }
    }
  }
}
</script>

<style>
body, p, h1, h2, h3, h4, h5, h6, span, a, li, td, th, label, input, textarea, button {
  /* Снандартный шрифт для всех текстовых тегов */
  font-family: 'Poppins' ,'Inter', sans-serif;
  margin: 0;
}
body{
  /* Убираем отступы с body */
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
}

html, body {
    overflow-x: hidden;
    width: 100vw;
}
.XTranslate{
  display: none;
}
</style>
